const ProjectDetailGallery = ({ gallery }) => {
  if (!gallery || gallery.length === 0) return null; // Prevents rendering if no images exist

  return (
    <div className="px-4 md:px-0 md:max-w-6xl mx-auto mt-10 sm:mt-20">
      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
        {gallery.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Gallery image ${index + 1}`}
            className="w-full h-48 sm:h-72 object-cover rounded-xl"
          />
        ))}
      </div>
    </div>
  );
};

export default ProjectDetailGallery;