import React, { useEffect, useState } from "react";
import philosophyData from "../../data/about/aboutPhilosophy.json";

const AboutPhilosophy = () => {
  const [philosophyItems, setPhilosophyItems] = useState([]);
  console.log("Philosophy Data:", philosophyData);

  useEffect(() => {
    setPhilosophyItems(philosophyData.philosophy);
  }, []);

  return (
    <section className="w-full py-24 px-8 md:px-20 lg:px-32">
      <div className="max-w-7xl mx-auto rounded-3xl bg-gray-800 backdrop-blur-lg shadow-lg p-12 md:p-40">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-28">
          {philosophyItems.map((item, index) => (
            <div key={index} className="flex flex-col space-y-4">
              <span className="text-3xl md:text-4xl font-semibold text-gray-600 pb-6">
                {String(index + 1).padStart(2, "0")}
              </span>
              <h3 className="text-3xl md:text-4xl font-bold text-green-300">
                {item.title}
              </h3>
              <p className="text-gray-300 text-lg md:text-xl">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutPhilosophy;
