const ProjectDetailProblemSolution = ({ problemSolution }) => {
  if (!problemSolution) return null; // Prevents rendering if no data is provided

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-10 mt-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
        {/* Problem Section */}
        <div className="bg-red-900/20 border border-red-500 rounded-2xl p-4 md:p-6 lg:p-8 shadow-lg flex flex-col">
          <h2 className="text-2xl md:text-3xl font-bold text-red-300 border-b border-red-400 pb-2">
            {problemSolution?.problemHeader}
          </h2>
          <p className="mt-2 text-md md:text-lg text-gray-300 leading-relaxed">
            {problemSolution?.problemDescription}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {problemSolution?.problemPoints?.map((point, index) => (
              <div
                key={index}
                className="flex items-start gap-2 p-3 bg-red-900/30 rounded-lg"
              >
                <span className="text-lg">{point.icon}</span>
                <div>
                  <h3 className="text-lg font-semibold text-red-300">
                    {point.title}
                  </h3>
                  <p className="text-sm text-gray-300">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Solution Section */}
        <div className="bg-green-900/20 border border-green-500 rounded-2xl p-4 md:p-6 lg:p-8 shadow-lg flex flex-col">
          <h2 className="text-2xl md:text-3xl font-bold text-green-300 border-b border-green-400 pb-2">
            {problemSolution?.solutionHeader}
          </h2>
          <p className="mt-2 text-md md:text-lg text-gray-300 leading-relaxed">
            {problemSolution?.solutionDescription}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            {problemSolution?.solutionPoints?.map((point, index) => (
              <div
                key={index}
                className="flex items-start gap-2 p-3 bg-green-900/30 rounded-lg"
              >
                <span className="text-lg">{point.icon}</span>
                <div>
                  <h3 className="text-lg font-semibold text-green-300">
                    {point.title}
                  </h3>
                  <p className="text-sm text-gray-300">{point.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailProblemSolution;
