const ProjectDetailMetadata = ({ metadata }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-32 items-center text-gray-300 text-xs sm:text-lg pt-10">
      {/* Timeline */}
      <div className="text-center sm:text-left">
        <h3 className="text-md sm:text-lg text-gray-200">
          {metadata?.timelineHeader}
        </h3>
        <p className="mt-2 sm:mt-6 text-green-300">{metadata?.timeline}</p>
      </div>

      {/* Team */}
      <div className="text-center sm:text-left">
        <h3 className="text-md sm:text-lg text-gray-200">
          {metadata?.teamHeader}
        </h3>
        <div className="flex justify-center sm:justify-left mt-2 sm:mt-6 ">
          {metadata?.team?.map((member, index) => (
            <div key={index} className="relative group">
              {/* Profile Image */}
              <img
                src={member.image}
                alt={member.name}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full ring-2 ring-gray-200 transition duration-300"
              />
              {/* Tooltip with Name */}
              <div className="absolute bottom-10 bg-green-300 text-black text-xs sm:text-md px-3 py-1 rounded-md shadow-lg opacity-0 group-hover:opacity-100 transition-all duration-300 whitespace-nowrap">
                {member.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Role */}
      <div className="text-center sm:text-left max-w-xs">
        <h3 className="text-md sm:text-lg text-gray-200">
          {metadata?.roleHeader}
        </h3>
        <p className="mt-2 sm:mt-6 text-green-300 break-words">
          {metadata?.role}
        </p>
      </div>
    </div>
  );
};

export default ProjectDetailMetadata;
