import React, { useState, useEffect } from "react";
import aboutpersonal from "../../data/about/aboutpersonal.json";

export default function AboutPersonal() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check screen size and update state
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // md breakpoint in Tailwind
    };

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const personal = isMobile
    ? aboutpersonal.smDescription
    : aboutpersonal.mdDescription;

  return (
    <section className="text-white pb-20 py-0 md:py-10">
      <div className="container mx-auto flex flex-col items-center justify-center px-6">
        <h2 className="text-4xl font-bold text-center mb-6">
          {isMobile ? "A Glimpse of Me" : "Who I Am"}
        </h2>

        {/* Styled Paragraphs */}
        <div className="max-w-2xl text-lg md:text-2xl text-gray-300 leading-relaxed space-y-6 text-left">
          <p>{personal.description}</p>
          <p>{personal.description2}</p>
          <p>{personal.description3}</p>
        </div>
      </div>
    </section>
  );
}
