import React from "react";
import {
  FaGithub,
  FaLinkedin,
  FaYoutube,
  FaEnvelope,
  FaInstagram,
} from "react-icons/fa";

const contact = [
  {
    name: "GitHub",
    icon: <FaGithub />,
    link: "https://github.com/EthDevelopment",
  },
  {
    name: "LinkedIn",
    icon: <FaLinkedin />,
    link: "https://linkedin.com/in/swaleh",
  },
  {
    name: "YouTube",
    icon: <FaYoutube />,
    link: "https://www.youtube.com/@SwalehOnEarth",
  },
  {
    name: "Instagram",
    icon: <FaInstagram />,
    link: "https://www.instagram.com/swaleh.on.earth/",
  },
  {
    name: "Email",
    icon: <FaEnvelope />,
    link: "mailto:swalehwork98@gmail.com",
  },
];

export default function Contact() {
  return (
    <section className="min-h-screen flex flex-col items-center justify-center text-white py-16 px-6">
      <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
        Let's Connect
      </h1>
      <p className="text-gray-300 text-center mb-8">
        Sharing ideas, building projects, and making an impact.
      </p>

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-6">
        {contact.map((contact) => (
          <a
            key={contact.name}
            href={contact.link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4 bg-gray-800 p-4 rounded-lg transition transform hover:scale-105 hover:bg-gray-700"
          >
            <span className="text-3xl">{contact.icon}</span>
            <span className="text-lg font-medium">{contact.name}</span>
          </a>
        ))}
      </div>
    </section>
  );
}
