import React from "react";
import freelanceProjects from "../../data/freelance/freelanceProjects.json";

export default function FreelanceCards() {
  return (
    <div className="grid  grid-cols-1 md:grid-cols-2 gap-6 max-w-8xl mx-auto pt-36">
      {freelanceProjects.map((project) => (
        <a
          key={project.id}
          href={project.href}
          target="_blank"
          rel="noopener noreferrer"
          className="group border-2 border-green-300 block w-full p-10 rounded-xl shadow-lg bg-gradient-to-br from-gray-900 to-gray-800 hover:shadow-2xl transition duration-300 flex items-center justify-between"
        >
          {/* Text Section */}
          <div className="flex flex-col gap-2 text-white">
            <span className="text-xs uppercase tracking-wide text-green-400 font-medium">
              {project.category}
            </span>
            <h2 className="text-lg md:text-xl font-semibold">
              {project.title}
            </h2>
            <p className="text-sm text-gray-300 leading-relaxed">
              {project.description}
            </p>

            {/* Tech Stack */}
            <div className="mt-3 flex flex-wrap gap-2">
              {project.stack.length > 0 ? (
                project.stack.map((tech, index) => (
                  <span
                    key={index}
                    className="bg-gray-700 text-gray-300 text-xs px-3 py-1 rounded-full"
                  >
                    {tech}
                  </span>
                ))
              ) : (
                <span className="text-gray-400 text-sm">
                  No tech stack available
                </span>
              )}
            </div>
          </div>

          {/* Circular Image Section */}
          {project.image && (
            <div className="w-16 h-16 md:w-20 md:h-20 rounded-full bg-gray-700 flex items-center justify-center overflow-hidden shadow-lg">
              <img
                src={project.image}
                alt={project.title}
                className="w-3/4 h-3/4 object-contain"
              />
            </div>
          )}
        </a>
      ))}
    </div>
  );
}
