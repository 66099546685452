import React, { useState, useRef, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

export default function Navbar() {
  const links = [
    { name: "Work", path: "/" },
    { name: "About", path: "/about" },
    { name: "Freelance", path: "/freelance" },
    { name: "Contact", path: "/contact" },
  ];

  const location = useLocation();
  const [pillStyle, setPillStyle] = useState({});
  const [scrolled, setScrolled] = useState(false);
  const linkRefs = useRef([]);

  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Move active pill
  useEffect(() => {
    const activeIndex = links.findIndex(
      (link) => link.path === location.pathname
    );
    if (linkRefs.current[activeIndex]) {
      const { offsetLeft, offsetWidth } = linkRefs.current[activeIndex];
      setPillStyle({
        transform: `translateX(${offsetLeft}px)`,
        width: `${offsetWidth}px`,
      });
    }
  }, [location.pathname]);

  // Scroll to top when route changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <nav
      className={`z-50 fixed top-6 left-1/2 transform -translate-x-1/2 flex items-center transition-all duration-300 ${
        scrolled
          ? "nav-scroll-pill p-2 rounded-full shadow-md nav-blur"
          : "bg-transparent"
      }`}
      style={{ height: "60px" }} // Fixed height to prevent shifting
    >
      {/* Sliding Pill Background */}
      <div
        className="absolute h-9 bg-white bg-opacity-10 rounded-full transition-all duration-200 ease-in-out"
        style={pillStyle}
      ></div>

      {/* Navigation Links - Responsive Handling */}
      <div className="flex w-full justify-center items-center gap-3 md:gap-4 px-2 md:px-4">
        {links.map((link, index) => (
          <NavLink
            key={link.name}
            to={link.path}
            ref={(el) => (linkRefs.current[index] = el)}
            className={({ isActive }) =>
              `relative px-2 md:px-5 py-1 md:py-4 text-xs md:text-base font-light transition-all duration-200 z-50 ${
                isActive ? "text-white" : "text-gray-300 hover:text-white"
              }`
            }
          >
            {link.name}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}
