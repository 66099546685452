import React, { useEffect, useState } from "react";
import linksData from "../data/linksdata.json";

function Footer() {
  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    setFooterData(linksData);
  }, []);

  if (!footerData) return null; // Prevent rendering before data loads

  return (
    <footer className="w-full py-16 bg-transparent flex flex-col items-center text-gray-300">

      {/* Footer Content */}
      <div className="pb-60 flex flex-col md:flex-row w-full max-w-6xl justify-between px-6 text-2xl">
        {/* Copyright */}
        <p className="opacity-80 text-base sm:text-1xl">
          {footerData.copyright}
        </p>

        {/* Dynamic Links Sections */}
        <div className="flex flex-col md:flex-row gap-10">
          {footerData.sections.map((section, index) => (
            <div key={index} className="text-left">
              <p className="font-semibold text-gray-100 mb-3">
                {section.title}
              </p>
              <ul className="opacity-90 space-y-2">
                {section.links.map((link, i) => (
                  <li key={i}>
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-white transition text-base"
                    >
                      {link.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      {/* Decorative Element */}
      <div className="mt-14 flex space-x-3 opacity-70">
        {Array.from({ length: 30 }).map((_, i) => (
          <div key={i} className="w-1.5 h-1.5 bg-gray-500 rounded-full"></div>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
