const ProjectDetailFirstImage = ({ firstSection }) => {
  if (!firstSection?.image) return null; // Prevents errors if image is missing

  return (
    <div className="max-w-8xl mx-auto mt-10 px-4 sm:px-6">
      <img
        src={firstSection.image}
        alt={firstSection.imageText}
        className="w-full sm:w-4/5 md:w-full max-w-lg sm:max-w-4xl md:max-w-7xl h-auto object-contain rounded-2xl mx-auto"
      />
      <p> {firstSection.caption} </p>
    </div>
  );
};

export default ProjectDetailFirstImage;
