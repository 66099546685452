import React from "react";
import freelancePersonalPastData from "../../data/freelance/freelancePersonalPastData.json";

export default function FreelancePersonalPast() {
  return (
    <section className="min-h-screen py-16 px-6 sm:px-12 max-w-8xl mx-auto">
      <section className="flex flex-col items-center justify-start text-center max-w-7xl mx-auto  px-8">
        {/* Large Heading Above Everything */}
        <h1 className="text-5xl md:text-8xl font-bold md:font-extrabold text-green-300 pt-24 md:pt-32">
          {freelancePersonalPastData.title}
        </h1>
        <p className="text-base sm:text-lg md:text-xl text-gray-300 mt-4 max-w-2xl pt-5">
          {freelancePersonalPastData.description}
        </p>
        <p className="text-base sm:text-md md:text-lg text-gray-300 mt-4 max-w-2xl pt-2.5 pb-10">
          {freelancePersonalPastData.description2}
        </p>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {freelancePersonalPastData.projects.map((project) => (
          <div key={project.id} className="flex flex-col items-center">
            <div className="w-full aspect-[16/9] border-2 border-green-300 bg-gray-800 rounded-xl overflow-hidden shadow-lg">
              <img
                src={project.image}
                alt={project.alt}
                className="w-full h-full object-cover"
              />
            </div>
            {project.caption && (
              <p className="text-white text-sm mt-2 text-center">
                {project.caption}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
