import React from "react";
import AboutHero from "../../components/about/AboutHero";
import AboutExperience from "../../components/about/AboutExperience";
import AboutPhilosophy from "../../components/about/AboutPhilosophy";
import AboutGallery from "../../components/about/AboutGallery";
import AboutPersonal from "../../components/about/AboutPersonal";

const About = () => {
  return (
    <div className="text-white min-h-screen pt-20">
      {/* Hero Section */}
      <AboutHero />

      {/* Experience Timeline */}
      <AboutExperience />

      {/* Philosophy Section */}
      <AboutPhilosophy />

      {/* Personal Section */}
      <AboutPersonal />

      {/* Gallery Section */}
      <AboutGallery />
    </div>
  );
};

export default About;
