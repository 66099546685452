import { useNavigate } from "react-router-dom";

const ProjectCard = ({ category, title, description, sections, slug }) => {
  const navigate = useNavigate();
  const image = sections?.FirstSection?.image; // Extract image correctly
  const stack = sections?.TechStack?.techStack || []; // Extract tech stack

  return (
    <div
      onClick={() => navigate(`/projects/${slug}`)}
      className="relative cursor-pointer w-full sm:w-full md:w-4/5 lg:w-4/5 min-h-[30vh] lg:min-h-[25vh] rounded-3xl shadow-lg overflow-hidden group bg-gradient-to-br from-gray-900 to-gray-800 hover:shadow-2xl transition duration-300"
    >
      {/* Image Section */}
      {image && (
        <div className="h-[50%] w-full bg-gray-700 overflow-hidden">
          <img
            src={image}
            alt={title}
            className="w-full h-full object-cover rounded-t-xl"
          />
        </div>
      )}

      {/* Content Section */}
      <div className="p-6 flex flex-col gap-2 text-white">
        <span className="text-xs uppercase tracking-wide text-green-400 font-medium">
          {category}
        </span>
        <h2 className="text-lg md:text-xl font-semibold">{title}</h2>
        <p className="text-sm text-gray-300 leading-relaxed">{description}</p>

        {/* Tech Stack */}
        <div className="mt-4 flex flex-wrap gap-2">
          {stack.length > 0 ? (
            stack.map((tech, index) => (
              <span
                key={index}
                className="bg-gray-700 text-gray-300 text-xs px-3 py-1 rounded-full"
              >
                {tech}
              </span>
            ))
          ) : (
            <span className="text-gray-400 text-sm">
              No tech stack available
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
