const ProjectDetailFinalTextImage = ({ finalSection }) => {
  if (!finalSection) return null; // Prevents errors if no final section data

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-10 sm:mt-16 text-center">
      {/* Final Text Section */}
      <h2 className="text-2xl sm:text-3xl font-semibold text-green-400">
        {finalSection.finalTextTitle || "Final Thoughts"}
      </h2>
      <p className="mt-2 sm:mt-4 text-base sm:text-lg text-gray-300 leading-relaxed">
        {finalSection.finalText ||
          "Final reflections on the project and key takeaways."}
      </p>

      {/* Final Image */}
      {finalSection.finalImage && (
        <div className="max-w-6xl mx-auto rounded-3xl mt-10 sm:mt-20">
          <img
            src={finalSection.finalImage}
            alt={finalSection.finalImageText}
            className="w-full h-auto object-cover rounded-2xl"
          />
          <p className="mt-2 sm:mt-4 text-sm sm:text-md text-center text-gray-300 leading-relaxed">
            {finalSection.finalImageText}
          </p>
        </div>
      )}
    </div>
  );
};

export default ProjectDetailFinalTextImage;
