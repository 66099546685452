const ProjectDetailTechStack = ({ TechStack }) => {
  return (
    <div className="max-w-6xl mx-auto px-6 mt-20 text-center">
      {/* Tech Stack Pills - Centered */}
      <div className="flex flex-wrap justify-center gap-4 mt-6">
        {TechStack.techStack.map((tech, index) => (
          <span
            key={index}
            className="bg-white/10 text-gray-300 text-lg font-medium px-4 py-2 rounded-xl shadow-md backdrop-blur-lg transition duration-300 hover:bg-white/20"
          >
            {tech}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetailTechStack;
