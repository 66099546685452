import React, { useState, useEffect } from "react";
import freelanceHeroData from "../../data/freelance/freelanceherodata.json"; // Import JSON file

export default function FreelanceHero() {
  const [freelance, setFreelance] = useState(null);

  useEffect(() => {
    setFreelance(freelanceHeroData); // Set data from JSON
  }, []);

  if (!freelance) return null; // Prevent rendering before data loads

  return (
    <section className="flex flex-col items-center justify-start text-center max-w-7xl mx-auto  px-8">
      {/* Large Heading Above Everything */}
      <h1 className="text-5xl md:text-9xl font-bold md:font-extrabold text-green-300 pt-24 md:pt-32">
        {freelance.title}
      </h1>
      <p className="text-base sm:text-lg md:text-xl text-gray-300 mt-4 max-w-2xl pt-20">
        {freelance.description}
      </p>
    </section>
  );
}
