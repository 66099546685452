import React from "react";
import galleryData from "../../data/about/aboutgallery.json";

const AboutGallery = () => {
  return (
    <section className="max-w-6xl mx-auto md:mt-20 md:px-6 pb-32">
      {/* Mobile View - Only Mount Image */}
      <div className="sm:hidden  ">
        <img
          src="/assets/Gallery/mount2.png"
          alt="Detaching from screens - Human Training"
          style={{
            width: "100%",
            height: "65vh", // Adjust as needed
            objectFit: "cover",
          }}
        />
      </div>

      {/* Grid for larger screens */}
      <div className="hidden sm:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 auto-rows-auto">
        {galleryData.gallery.map((item, index) => (
          <div
            key={index}
            className={`relative overflow-hidden rounded-lg shadow-lg bg-gray-800 transition-transform duration-300 hover:scale-105 ${item.size}`}
          >
            <img
              src={item.src}
              alt={item.alt}
              className="w-full h-full object-cover"
            />
            <div className="absolute bottom-3 left-3 bg-gray-900 text-gray-200 text-sm px-4 py-2 rounded-md hidden sm:block">
              {item.caption}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutGallery;
