import React, { useEffect, useRef, useState } from "react";
import experienceData from "../../data/about/aboutexperience.json";

const AboutExperience = () => {
  const scrollContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Group job titles by year
  const yearToJobsMap = experienceData.experiences.reduce(
    (acc, { startYear, endYear, company, role }) => {
      for (let year = startYear; year <= endYear; year++) {
        if (!acc[year]) acc[year] = [];
        acc[year].push({ company, role });
      }
      return acc;
    },
    {}
  );

  const years = Object.keys(yearToJobsMap).sort((a, b) => a - b);

  // Scroll to the end on mount
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.scrollLeft = container.scrollWidth; // Start at the rightmost position
    }
  }, []);

  // Mouse Dragging Functions
  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const stopDragging = () => setIsDragging(false);

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust speed
    scrollContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  // Keyboard Navigation
  const handleKeyDown = (e) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    if (e.key === "ArrowRight") {
      container.scrollLeft += 150; // Scroll right
    } else if (e.key === "ArrowLeft") {
      container.scrollLeft -= 150; // Scroll left
    }
  };

  return (
    <section className="max-w-7xl mx-auto px-6 flex flex-col">
      {/* Scrollable Container */}
      <div
        ref={scrollContainerRef}
        className="relative flex space-x-10 h-full px-8 pb-4 overflow-x-auto snap-x snap-mandatory py-20 md:overflow-x-scroll scrollbar-hide custom-scrollbar"
        style={{
          overflowX: "scroll",
          overflowY: "hidden",
          scrollBehavior: "smooth",
        }}
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseUp={stopDragging}
        onMouseMove={handleMouseMove}
        onKeyDown={handleKeyDown}
        tabIndex={0} // Allows keyboard interaction
      >
        {years.map((year, index) => (
          <div
            key={index}
            className="group border-4 border-green-300 relative flex-shrink-0 w-80 h-[35rem] bg-gray-800 rounded-3xl shadow-1xl flex flex-col items-center justify-start text-4xl font-bold text-green-300 snap-center transition-transform duration-300 ease-in-out transform hover:scale-105 p-6"
          >
            <h3 className="text-5xl font-bold text-green-300">{year}</h3>
            <div className="mt-6 space-y-3 text-center text-lg font-medium w-full">
              {yearToJobsMap[year].map(({ company, role }, jobIndex) => (
                <div
                  key={jobIndex}
                  className="text-gray-200 border-2 border-green-300 bg-gray-700 px-4 py-2 rounded-lg w-full"
                >
                  <p className="font-semibold text-green-300">{role}</p>
                  <p className="text-sm text-gray-300">{company}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Hide scrollbar */}
      <style>
        {`
  /* Hide scrollbar on small screens */
  @media (max-width: 767px) {
    .scrollbar-hide::-webkit-scrollbar {
      display: none;
    }
    .scrollbar-hide {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

  /* Style scrollbar for md+ screens */
  @media (min-width: 768px) {
    /* WebKit Browsers (Chrome, Safari, Edge) */
    .custom-scrollbar::-webkit-scrollbar {
      height: 8px; /* Set height of scrollbar */
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: transparent; /* No background */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: #86efac; /* Tailwind green-300 */
      border-radius: 4px; /* Smooth edges */
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background-color: #4ade80; /* Tailwind green-400 for hover effect */
    }

    /* Firefox */
    .custom-scrollbar {
      scrollbar-color: #86efac transparent; /* Thumb color + transparent track */
    }
  }
  `}
      </style>
    </section>
  );
};

export default AboutExperience;
