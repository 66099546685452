import React, { useState, useEffect } from "react";
import userdata from "../../data/userdata.json"; // Import JSON data

export default function Hero() {
  const [user, setUser] = useState({ name: "", title: "", description: "" });

  useEffect(() => {
    setUser(userdata); // Load data from JSON
  }, []);

  return (
    <section className="h-auto flex flex-col justify-start items-center text-center pt-40">
      <h1 className="text-5xl md:text-8xl font-bold leading-tight text-green-300 ">
        Hi. I'm {user.name}.
      </h1>
      <h2 className="text-4xl md:text-8xl font-bold text-green-300">
        {user.title}
      </h2>
      <p className="text-base sm:text-lg md:text-xl text-gray-300 mt-4 max-w-2xl pt-20">
        {user.description}
      </p>
    </section>
  );
}
