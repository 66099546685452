import React, { useState, useEffect } from "react";
import aboutData from "../../data/about/aboutdata.json"; // Import JSON file

export default function AboutHero() {
  const [about, setAbout] = useState(null);

  useEffect(() => {
    setAbout(aboutData); // Set data from JSON
  }, []);

  if (!about) return null; // Prevent rendering before data loads

  return (
    <section className="min-h-screen flex flex-col items-center justify-start text-center max-w-7xl mx-auto pt-20 md:pt-32 px-8">
      {/* Large Heading Above Everything */}
      <h1 className="text-5xl md:text-9xl font-bold md:font-extrabold text-green-300">
        I'm {about.name}.
      </h1>

      {/* Container for Image + Text */}
      <div className="flex flex-col md:flex-row items-center justify-center w-full gap-12 mt-16 md:mt-24">
        {/* Left - Profile Image */}
        <div className="w-full md:w-1/3 flex justify-center">
          <div className="w-82 h-82 md:w-72 md:h-72 md:w-96 md:h-96 bg-gray-700 rounded-3xl overflow-hidden shadow-xl">
            <img
              src={about.image}
              alt={about.name}
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Right - Text Content */}
        <div className="w-full md:w-2/3 flex flex-col justify-center text-left sm:px-6 md:px-10 lg:px-20">
          <h2 className="text-3xl md:text-7xl font-bold text-green-400 mt-4">
            {about.title}
          </h2>
          <div className="mt-6 text-gray-300 text-xl leading-relaxed space-y-6 max-w-full md:max-w-3xl lg:max-w-4xl">
            {/* Render different descriptions based on screen size */}
            <p className="hidden md:block">{about.mdDescription.description}</p>
            <p className="hidden md:block">
              {about.mdDescription.description2}
            </p>
            <p className="hidden md:block">
              {about.mdDescription.description3}
            </p>

            <p className="block md:hidden">{about.smDescription.description}</p>
            <p className="block md:hidden">
              {about.smDescription.description2}
            </p>
            <p className="block md:hidden">
              {about.smDescription.description3}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
