import React from "react";
import FreelanceHero from "../../components/freelance/FreelanceHero";
import FreelanceCards from "../../components/freelance/FreelanceCards";
import FreelancePersonalPast from "../../components/freelance/FreelancePersonalPast";
import FreelanceProjectsPast from "../../components/freelance/FreelanceProjectsPast";

export default function Freelance() {
  return (
    <section className="min-h-screen py-16 px-6 sm:px-12">
      <FreelanceHero />
      <FreelanceCards />
      <FreelanceProjectsPast />
      <FreelancePersonalPast />
    </section>
  );
}
