import { useParams } from "react-router-dom";
import projectsData from "../../data/projects.json";
import ProjectDetailHero from "../../components/carddetails/ProjectDetailHero";
import ProjectDetailMetadata from "../../components/carddetails/ProjectDetailMetadata";
import ProjectDetailFirstImage from "../../components/carddetails/ProjectDetailFirstImage";
import ProjectDetailOverview from "../../components/carddetails/ProjectDetailOverview";
import ProjectDetailSecondImage from "../../components/carddetails/ProjectDetailSecondImage";
import ProjectDetailExploration from "../../components/carddetails/ProjectDetailExploration";
import ProjectDetailTechStack from "../../components/carddetails/ProjectDetailTechStack";
import ProjectDetailGallery from "../../components/carddetails/ProjectDetailGallery";
import ProjectDetailFinalTextImage from "../../components/carddetails/ProjectDetailFinalTextImage";
import ProjectDetailProblemSolution from "../../components/carddetails/ProjectDetailProblemSolution";
const ProjectDetail = () => {
  const { slug } = useParams();
  const project = projectsData.find((p) => p.slug === slug);

  if (!project) {
    return <p className="text-white text-center mt-10">Project not found.</p>;
  }

  return (
    <div className="min-h-screen text-white pt-40">
      {/* Hero Section */}
      <ProjectDetailHero title={project.title} />

      {/* Metadata */}
      <ProjectDetailMetadata metadata={project.sections["metadata"]} />

      {/* Tech Stack Section */}
      <ProjectDetailTechStack TechStack={project.sections["TechStack"]} />

      {/* First Image - Full Viewport Width */}
      <ProjectDetailFirstImage
        firstSection={project.sections["FirstSection"]}
      />

      {/* Problem & Solution Section */}
      <ProjectDetailProblemSolution
        problemSolution={project.sections["ProblemSolution"]}
      />

      {/* Overview Section */}
      <ProjectDetailOverview firstSection={project.sections["FirstSection"]} />

      {/* Second Image - Styled Similarly */}
      <ProjectDetailSecondImage
        secondSection={project.sections["SecondSection"]}
      />

      {/* Exploration Section */}
      <ProjectDetailExploration
        secondSection={project.sections["SecondSection"]}
      />

      {/* Gallery Section */}
      <ProjectDetailGallery
        gallery={project.sections["ThirdSection"]?.gallery}
      />

      {/* Final Section */}
      <ProjectDetailFinalTextImage
        finalSection={project.sections["FinalSection"]}
      />
    </div>
  );
};

export default ProjectDetail;
