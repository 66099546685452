const ProjectDetailExploration = ({ secondSection }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-20 md:px-60 mt-10 sm:mt-20">
      <h2 className="text-xl sm:text-3xl md:text-5xl font-semibold text-green-300 text-left sm:text-left">
        {secondSection?.Header2}
      </h2>
      <p className="mt-2 sm:mt-4 text-md sm:text-xl md:text-2xl text-green-200 leading-relaxed text-left sm:text-left">
        {secondSection?.Description2}
      </p>
    </div>
  );
};

export default ProjectDetailExploration;
