const ProjectDetailSecondImage = ({ secondSection }) => {
  if (!secondSection?.image) return null; // Prevents errors if image is missing

  return (
    <div className="max-w-8xl mx-auto mt-10 px-4 sm:px-6">
      <img
        src={secondSection.image}
        alt={secondSection.imageText}
        className="w-full sm:w-4/5 md:w-full max-w-lg sm:max-w-4xl md:max-w-7xl h-auto object-contain rounded-2xl mx-auto"
      />
    </div>
  );
};

export default ProjectDetailSecondImage;
