import React, { useEffect, useState } from "react";
import Hero from "../../components/work/Hero";
import ProjectCard from "../../components/work/ProjectCard";
import projectsData from "../../data/projects.json";

function Work() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  return (
    <div>
      <Hero />
      <div className="mt-10 px-6 min-h-screen flex justify-center items-center">
        <div className="flex flex-col items-center gap-16 w-full sm:p-1 md:p-10 min-h-screen pb-20">
          {projects?.length > 0 ? (
            projects.map((project) => (
              <ProjectCard
                key={project.id}
                category={project.category}
                title={project.title}
                description={project.description}
                sections={project.sections} // Pass entire sections object
                slug={project.slug}
              />
            ))
          ) : (
            <p className="text-gray-300">No projects available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Work;
